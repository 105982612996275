import { countriesObject } from 'common/countries'
import { BusinessType } from './business-type'
import { Address } from './requests'

export interface PagedResponse<T> {
  count: number
  next?: string
  currentPage: number
  totalPages: number
  previous?: string
  results: T[]
}

type Image = {
  url: string
}

export type PhoneDetails = {
  'company_product_subscription_id': string
  'company_name': string
  'number': string
  'contact_number': string
  'phone_id': string
}

export enum CompanyType {
  'LIMITED_COMPANY' = 'LIMITED_COMPANY',
  'SOLE_TRADER' = 'SOLE_TRADER',
  'NON_PROFIT' = 'CHARITY',
  'UNINCORPORATED' = 'UNINCORPORATED',
  'TRUST' = 'TRUST',
  'ASSOCIATION' = 'ASSOCIATION',
}

export type CompanyDetail = {
  legal_name: string
  company_number: string
  formation: string
  trading_name: string
  business_type: string
  is_email_notifications: boolean
  country_of_incorporation: string
  nature_of_business: string
  partner?: {
    allow_request_forwards: boolean
    id: number
    name: string
  }
}

export type Brand = {
  id: string
  name: string
  logo: Image
  description: string
}

type MembershipTypes = 'MEMBER' | 'STARTUP_CARD'

export type Offer = {
  hero_background_gradient: string | null
  hero_background_color: string | null
  background_gradient?: string
  brand: Brand
  content: string // html
  secured_content?: string // html
  description: string
  featured: boolean
  hero_description: string
  hero_image: Image
  id: string
  image: Image
  requirements: MembershipTypes[] // ignore for now
  screenshots: Image[]
  secured: boolean
  tags: string[]
  terms_content: string //html
  title: string
}

export type OfferSummary = {
  id: string
  featured: boolean
  image: Image
  tags: string[]
  background_color: string
  background_gradient?: string
  brand: Brand
  title: string
  metadata_title?: string
  description: string
  secured: boolean
}

export type Tag = {
  id: string
  name: string
  tag_type: 'OFFER'
}

export type OfferRedemption = {
  url: string
  coupon_code?: string
  has_coupons?: boolean
}

export type Profile = {
  username: string
  attributes: ProfileAttributes
  companies: Company[]
}

export type ProfileAttributes = {
  sub: string
  email_verified: boolean
  preferred_username: string
  email: string
}
export type Url = {
  url: string
}
export enum BillingPeriod {
  'MONTHLY' = 1,
  'ANNUAL' = 2,
}

type SubscriptionAddon = {
  name: string
  description: string
  price: number
}

export type CompanySubscription = {
  id: number
  chargebee_id: string
  contact_email: string
  is_restricted_access: boolean
  contact_name: string
  contact_telephone: string
  company_name: string
  address: Address
  addons?: SubscriptionAddon[]
  is_admin: boolean
  name: string
  description: string
  price: number
  total: number
  is_cancelled: boolean
  billing_period: BillingPeriod
  next_payment_date: number
  kyc_status: KYCStatus
  phone: {
    business_number?: string
    forwarding_number?: string
  }
}

export type TeamMember = {
  id: string
  first_name: string
  is_owner: boolean
  last_name: string
  email: string
  role_id: string
  created_at: string
  authorized_to_collect_post: boolean
}

export interface CheckoutFormValuesType {
  first_name: string
  last_name: string
  phone_number: string
}

export type Company = {
  id: string
  chargebee_id: string
  name: string
  subscriptions: CompanySubscription[] // need to ignore addons / attach them to the product subscription
  account_balance: number
  'legal_name': string
  'company_number': string
  'formation': string
  'trading_name': string
  'acn_number': string
  'abn_number': string
  role: Role
  is_owner: boolean
  'business_type': BusinessType
  country_of_corporation: keyof typeof countriesObject
  'nature_of_business': 'nature_of_business'
  company_accounts?: {
    'accounts_next_due_date': string
    'accounts_last_made_up_date': string
    'confirmation_statement_next_due_date': string
    'confirmation_statement_last_made_up_date': string
    'returns_next_due_date': string
    'returns_last_made_up_date': string
  }
} & CompanyDetail

export type InvoiceSummary = {
  id: string
  status: string
  line_items: LineItem[]
  total: number
  date: number
  due_date: number
}

export type LineItemType =
  | 'plan_item_price'
  | 'adhoc'
  | 'addon_item_price'
  | 'charge_item_price'

export type LineItem = {
  amount: number
  description: string
  discount_amount: number
  entity_type: LineItemType
  id: string
  item_level_discount_amount: number
  pricing_model: string
  quantity: number
  tax_amount: number
  unit_amount: number
}

export type InvoiceDownload = {
  url: string
}

export type MailDownload = {
  urls: string[]
}

export type ManageSubscriptionURL = {
  access_url: string
  status: string
  token: string
}

export type PaymentSourceStatus =
  | 'valid'
  | 'expiring'
  | 'expired'
  | 'invalid'
  | 'pending_verification'

export type PaymentSourceType =
  | 'card'
  | 'paypal_express_checkout'
  | 'amazon_payments'
  | 'direct_debit'

export interface PaymentSource {
  created_at: number
  id: string
  status: PaymentSourceStatus
  type: PaymentSourceType
}

export interface VerifyAccount {
  id_token: string
  token: string
  first_name: string
  last_name: string
  refresh_token: string
}
export type MailActionType =
  | 'FORWARD'
  | 'SCAN'
  | 'DOWNLOAD'
  | 'MARK_AS_READ'
  | 'MARK_AS_UNREAD'
export type MailAction = {
  action_type: MailActionType
  requires_topup: boolean
  price?: number
}

export const KYCName = {
  'ADDRESS': 'Proof of Address',
  'ID': 'Identification',
}

export type KYCLink = {
  link_type: 'ID' | 'ADDRESS'
  status: KYCStatusType
  link: string
}

export type KYCMember = {
  id: string
  first_name: string
  company_name: string
  last_name: string
  start_verification?: boolean
  middle_name: string
  email: string
  dob: string
  address: Address
  customer_id: string | null
  portal_link: string | null
  kyc_links: KYCLink[]
}
export type MailStatus =
  | 'ACCOUNT_FROZEN'
  | 'COLLECTED'
  | 'CREATED'
  | 'DESTROYED'
  | 'FORWARDED'
  | 'ON_HOLD_INVOICE'
  | 'ON_HOLD_KYC_ID'
  | 'PENDING'
  | 'PROCESSING'
  | 'REFUSED'
  | 'REJECTED'
  | 'REQUESTED_FORWARD'
  | 'REQUESTED_SCAN'
  | 'RETURNED'
  | 'SCANNED'

export interface MailMetadata {
  document_title: string
  content: string
  reference_number: string
  summary: string
  industry: string
  date_of_issue: string
  categories: string[]
  sub_categories: string[]
  key_information: { 'key': string; 'value': string }[]
  sender_name: string
}

export interface Mail {
  company_id: number
  date: string
  expiry: string
  address: string | null
  barcode_id: string | null
  id: string
  is_mail_collected: boolean
  processed_on: string
  processed_by: string
  status: MailStatus
  actions: MailAction[]
  read: boolean
  state: string
  metadata: MailMetadata | null
  parcel_dimensions?: ParcelDimensions
  //Added by FE
  expired: boolean
  expiring: boolean
  sender?: {
    name: string
    logo?: { url: string }
  }
}

export type ParcelDimensions = {
  width?: number
  height?: number
  length?: number
  weight?: number
}

export type MailEvent = {
  event_name: string
  description: string
  actor: string
  created_at: string
}
export interface MailItem extends Mail {
  is_mail_collected: boolean
  processed_on: any
  scan_urls: string[] | null
  envelope_urls: string[] | null
  events?: MailEvent[]
}

export type CompanyIDStatus =
  | 'NotChecked'
  | 'EmailSent'
  | 'EmailClicked'
  | 'IdSubmitted'
  | 'IdCheckedAwaitingSecondOpinion'
  | 'IdCheckedConfirmed'
export type CompaniesHouseStatus =
  | 'NotChecked'
  | 'CheckedFound'
  | 'CheckedNotFound'
  | 'CheckedFoundInternational'

export const KYCStatusDescription = {
  'APPROVED': 'Owner has passed verification.',
  'CREATED': 'Owner has not filled out verification.',
  'DECLINED': 'Verification has been rejected.',
  'EXPIRED': 'Owner has not filled out verification.',
  'NOT_STARTED': 'Owner has not filled out verification.',
  'STARTED': 'Owner has start KYC but not submitted.',
  'SUBMITTED': 'Verification has been submitted and is in review.',
}

export type KYCStatusType =
  | 'NOT_STARTED'
  | 'STARTED'
  | 'EXPIRED'
  | 'SUBMITTED'
  | 'APPROVED'
  | 'DECLINED'
  | 'CREATED'

export type KYCStatus = {
  'ltd_company_id_status': CompanyIDStatus
  'companies_house_status': CompaniesHouseStatus
  'kyc_completed': boolean
  'kyc_link': 'string'
}

export type Permission = {
  name: string
  code:
    | 'MANAGE_ACCOUNT' // Access the mail link
    | 'READ_MAIL' // Access the mail link
    | 'ACTION_MAIL' // Backend: dictates whether paid actions come back for mail (Scan / Forward)
    | 'TOPUP' // Add funds to account
    | 'MANAGE_BILLING' // Access chargebee
    | 'READ_INVOICE' // Access account and billing link
    | 'READ_MEMBER' // Access teams link
    | 'INVITE_MEMBER' // Invite members button
    | 'DELETE_MEMBER' // Delete members button
    | 'EDIT_MEMBER' // Edit members button
}

export type RoleSummary = {
  id: string
  name: string
  description: string
}
export type Role = RoleSummary & {
  permissions: Permission['code'][]
}
export type MailCompanySummary = {
  name: string
  logo?: { url: string }
}

export type CategoryName =
  | 'Financial'
  | 'Utilities & Services'
  | 'Taxation'
  | 'Government & Public Sector'
  | 'Human Resources'
  | 'Official / Legal'
  | 'Suppliers & Vendors'
  | 'Marketing & Advertising'
  | 'Technology & IT'
  | 'Industry Updates'
  | 'Clients & Customers'

export type Res = {
  offers: PagedResponse<OfferSummary>
  offer: Offer
  tags: Tag[]
  offerRedemption: OfferRedemption
  user: Profile
  redirect: Url
  login: {}
  companies: Company[]
  invoices: InvoiceSummary[]
  invoiceDownload: InvoiceDownload
  manageSubscription: ManageSubscriptionURL
  paymentSources: PaymentSource
  verifyAccount: VerifyAccount
  mail: PagedResponse<Mail>
  requestDownload: MailDownload
  company: CompanyDetail
  kycStatus: KYCStatus
  topUp: { url?: string }
  scanMail: { id: string }
  requestHardCopy: { id: string }
  verifyTopup: {}
  mailItem: MailItem
  mailCounts: { count: number }
  mailCount: { id: string }
  teamMembers: PagedResponse<TeamMember>
  invite: {}
  teamMember: {}
  companyRoles: RoleSummary[]
  groupedOffers: {
    tags: Tag[]
    groups: {
      tag: Tag
      offers: PagedResponse<OfferSummary>
    }[]
  }
  devSettings: {
    testData: boolean
    mockVerification: boolean
    enabled: boolean
    environment?: 'staging' | 'production'
    whiteLabelLoaded?: boolean
    whitelabel?: string
    project?: string
  }
  userData: {
    first_name: string | null | undefined
    last_name: string | null | undefined
  }

  notifications: PagedResponse<Notification>
  notificationsCounts: {
    count: number
  }
  notificationsRead: {}
  userAnalytics: {
    loginCount: number
    walletPrompt: string | null
    preventWalletPrompt: boolean | number
    hasAddedPass: boolean
  }
  kycLink: {}
  kycMembers: PagedResponse<KYCMember>
  kycMember: KYCMember
  requestKYCVerification: { id: string }
  thing: { id: string }
  kycStep: null | 'id' | 'address' | 'complete'
  account: CheckoutFormValuesType
  startVerification: { id: string }
  phones: PhoneDetails[] | null
  phone: {}
  verificationDocument: { id: string }
  changeForwardAddress: {}
  changeEmail: {}
  mailCategories: {
    'senders': MailCompanySummary[]
    'categories': [{ 'count': number; 'name': CategoryName }]
  }
  selectedMails: string[]
  getOfferRedemptionUrl: { [key: string]: string }
  subscriptionNotifications: { id: string }
  // END OF TYPES
}
